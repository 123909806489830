import React from 'react';
import PropTypes from 'prop-types';
import './question.scss';

const Question = props => (
  <div className="question">
    <header className="question-header" onClick={() => props.onClick(props.id)}>
      {props.question}
    </header>
    <div className={`answer${props.visible ? ' visible' : ''}`}>
      {props.answer}
    </div>
  </div>
);

Question.defaultProps = {
  visible: false
};

Question.propTypes = {
  question: PropTypes.string,
  visible: PropTypes.bool,
  answer: PropTypes.string
};

export default Question;
