import React, { Component } from 'react';
import scrollToElement from 'scroll-to-element';
import Logo from '../../images/logo.png';
import Container from '../container';
import Row from '../row';
import Col from '../col';
import Title from '../title';
import Button from '../button';
import api from '../../api';
import './header.scss';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      banner: ''
    };
  }
  componentDidMount() {
    api.get('/banner').then(res => {
      this.setState({
        banner: res.data.url
      });
    });
  }
  rolar() {
    scrollToElement('#scrolltest', {
      offset: -150,
      duration: 800
    });
  }
  render() {
    return (
      <>
        <header className="header">
          <img className="logo" src={Logo} alt="Nome do sistema" />
          <img
            className="header-image"
            src={this.state.banner}
            alt="Banner do Sistema"
          />
          <div className="header-content">
            <Container full>
              <Row full center>
                <Col size={6}>
                  <Title color="inverse" strong>
                    Simplifique o dia a dia de seu restaurante
                  </Title>
                  <br />
                  <Button onClick={this.rolar}>Saber mais</Button>
                </Col>
              </Row>
            </Container>
          </div>
        </header>
      </>
    );
  }
}
export default Header;
