import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Container = props => (
  <div className={`container${props.full ? ' full' : ''}`}>
    {props.children}
  </div>
);

Container.defaultProps = {
  full: false,
  center: false
};

Container.propTypes = {
  full: PropTypes.bool,
  center: PropTypes.bool
};


export default Container;