import React from 'react';
import PropTypes from 'prop-types';
import './title.scss';

const Title = props => (
  <h1
    className={`title ${props.color}${props.strong ? ' strong' : ''}`}
    style={props.style}
  >
    {props.children}
  </h1>
);

Title.defaultProps = {
  color: 'default',
  strong: false,
  style: {}
};

Title.propTypes = {
  color: PropTypes.oneOf(['default', 'inverse']),
  strong: PropTypes.bool,
  style: PropTypes.object
};

export default Title;
