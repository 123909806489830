import React from 'react';
import './opt.scss';

const Opt = props => (
  <div
    className={`opt opt-${props.index} ${props.active ? 'active' : ''}`}
    onClick={props.onClick}
  >
    <img src={props.icon} alt="Ícone" />
  </div>
);

export default Opt;
