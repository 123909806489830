import React, { Component } from 'react';
import Slider from 'react-slick';
import Lightbox from 'lightbox-react';
import PropTypes from 'prop-types';
import './gallery.scss';

const settings = {
  className: 'center',
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  touchMove: false,
  speed: 500,
  dots: true,
  responsive: [
    {
      breakpoint: 800,
      settings: {
        touchMove: true,
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]
};

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false
    };

    this.viewImage = this.viewImage.bind(this);
  }

  viewImage(photoIndex) {
    this.setState({
      photoIndex,
      isOpen: true
    });
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    const { images } = this.props;

    return (
      <div style={{ padding: '0 15px', position: 'relative' }}>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div
              className="view-image"
              key={index}
              onClick={() => this.viewImage(index)}
            >
              <img src={image.thumb} alt="Slide" />
            </div>
          ))}
        </Slider>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].original}
            nextSrc={images[(photoIndex + 1) % images.length].original}
            prevSrc={
              images[(photoIndex + images.length - 1) % images.length].original
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
          />
        )}
      </div>
    );
  }
}

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object)
};

export default Gallery;
