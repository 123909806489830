import React from "react";

const Form = props => {
    const submitForm = e => {
        e.preventDefault();
        props.onSubmit(e);
    };

    return <form onSubmit={submitForm}>{props.children}</form>;
};

export default Form;