import React from 'react';
import ReactModal from 'react-modal';
import { Form, Input, Textarea } from '../form';
import Row from '../row';
import Col from '../col';
import Button from '../button';
import { Close } from '../close';
import api from '../../api';
import Loading from '../loading';
import './modal.scss';

const sendMessage = ({ name, phone, email, message }) => {
  const params = new URLSearchParams();
  params.append('name', name);
  params.append('phone', phone);
  params.append('email', email);
  params.append('message', message);

  return api.post('/contact', params);
};

ReactModal.setAppElement('#root');

const defaultState = {
  loading: false,
  name: '',
  phone: '',
  email: '',
  message: ''
};

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value
    });
  }

  handleSubmit() {
    this.setState({ loading: true });

    sendMessage(this.state)
      .then(({ data }) => {
        alert(data.message);

        if (data.success) {
          this.setState(defaultState);
          this.props.onClose();
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        alert('Não foi possível enviar sua mensagem!');
      });
  }

  render() {
    return (
      <>
        <ReactModal isOpen={this.props.isOpen} className="modal">
          <Close onClick={this.props.onClose} />
          <Form onSubmit={this.handleSubmit}>
            <h1 style={{ textAlign: 'center' }}>Contato</h1>
            <Row>
              <Col>
                <Input
                  name="name"
                  text="Nome"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  name="phone"
                  text="Telefone"
                  value={this.state.phone}
                  onChange={this.handleChange}
                />
              </Col>
              <Col>
                <Input
                  name="email"
                  text="E-mail"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Textarea
                  name="message"
                  text="Sua mensagem"
                  value={this.state.message}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <div style={{ textAlign: 'center' }}>
              <Button type="submit" value="Submit">
                Enviar
              </Button>
            </div>
          </Form>
        </ReactModal>
        {this.state.loading && <Loading />}
      </>
    );
  }
}

export default Modal;
