import React from 'react';
import './list.scss';

const List = props => (
  <div className="lista">
    <ul>
      {props.data.map(t => (
        <li key={t.id}>{t.texto}</li>
      ))}
    </ul>
  </div>
);

export default List;
