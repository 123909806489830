import React from 'react';
import './form.scss';

const Textarea = ({ name, text, ...props }) => (
  <textarea
    rows="10"
    className="form-control"
    name={name}
    placeholder={text}
    required
    {...props}
  />
);

export default Textarea;
