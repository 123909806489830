import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Question from '../question';

class FAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0
    };

    this.setCurrent = this.setCurrent.bind(this);
  }

  setCurrent(id) {
    this.setState(state => ({ current: state.current !== id ? id : 0 }));
  }

  render() {
    return (
      <div id='scrolltest'>
        {this.props.questions.map(props => (
          <Question
            key={props.id}
            visible={this.state.current === props.id}
            onClick={this.setCurrent}
            {...props}
          />
        ))}
      </div>
    );
  }
}

FAQ.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object)
};

export default FAQ;
