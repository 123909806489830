import React from 'react';
import Header from '../components/header';
import Main from '../components/main';
import Footer from '../components/footer';
import './app.scss';

const App = () => (
  <>
    <Header />
    <Main />
    <Footer />
  </>
);

export default App;
