import React from 'react';
import Typist from 'react-typist';
import './gest.scss';

const Gest = props => (
  <div>
    <div className="gest">
      {props.data.map((t, index) =>
        index === props.current ? (
          <Typist
            key={t.id}
            avgTypingDelay={20}
            cursor={{ element: '' }}
            onTypingDone={props.changeMude}
          >
            <ul>
              {t.items.map(i => (
                <li key={i.id}>{i.texto}</li>
              ))}
            </ul>
          </Typist>
        ) : (
            ''
          )
      )}
    </div>
  </div>
);

export default Gest;
