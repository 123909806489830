import React from 'react';
import Opt from './opt';
import './opt.scss';

const Options = props => (
  <div id="options">
    {props.data.map((o, index) => (
      <Opt
        key={o.id}
        id={o.id}
        index={index}
        active={index === props.current}
        onClick={() => props.onClick(index)}
        icon={o.url_icon}
      />
    ))}
  </div>
);

export default Options;
